import React, { useEffect, useState, useRef } from "react";
import "./TKMemberWorkplacesForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import InputMask from "react-input-mask";
import TKFileInput from "../../TKInputs/TKFileInput/TKFileInput";
import TKFilterDropdown from "../../../TKFilterDropdown/TKFilterDropdown";
import { getTKCategoriesRequest } from "../../../../store/actions/categories";
import { getTKCardTypesRequest } from "../../../../store/actions/cardTypes";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { checkOnChangeNumeric } from "../../../../helpers/validate";
import TKSelect from "../../../TKSelect/TKSelect";
import { statusLabel } from "../../../../constants/general";
import TKCoordinatesPicker from "../../TKInputs/TKCoordinatesPicker/TKCoordinatesPicker";
import { formatDate, ililce, ililce2 } from "../../../../helpers/utilities";
import TKDatePicker from "../../TKInputs/TKDatePicker/TKDatePicker";
const TKMemberWorkplacesForm = ({
  formData,
  formDataErrors,
  onChange,
  isPublic,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.categories);
  const { listCardTypes } = useSelector((state) => state.cardTypes);
  const [status, setStatus] = useState([
    { status: "AKTİF", _id: "ACTIVE" },
    { status: "PASİF", _id: "PASSIVE" },
    { status: "ONAY BEKLİYOR", _id: "WFA" },
    { status: "RED", _id: "CANCELED" },
  ]);

  const [isOnline, setIsOnline] = useState([
    { isOnline: "EVET", _id: "true" },
    { isOnline: "HAYIR", _id: "false" },
  ]);

  const [district, setDistrict] = useState(null);

  useEffect(() => {
    dispatch(getTKCategoriesRequest());
    dispatch(getTKCardTypesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!isEdit) createDiscounts();
  }, [listCardTypes]);

  const createDiscounts = () => {
    let discountArray = [...(formData?.discounts || [])];

    listCardTypes.forEach((cardType) => {
      const exists = discountArray.find((d) => d.type === cardType._id);
      if (!exists) {
        discountArray.push({
          type: cardType._id,
          discounttype: "PERCENT", // Varsayılan değer
          amount: 0,
        });
      }
    });

    onChange("discounts", discountArray);
  };

  const handleDropdownOnChange = (id) => {
    const category = [...formData?.category];
    const idIndex = category.indexOf(id);
    if (idIndex !== -1) {
      category.splice(idIndex, 1);
      return onChange("category", category);
    }
    onChange("category", [...category, id]);
  };

  const withHttp = (url) =>
    url ? (!/^https?:\/\//i.test(url) ? `http://${url}` : url) : "";

  const PERCENT = "PERCENT";
  const FIXED = "FIXED";
  const amountLabel = {
    PERCENT: "Oranı",
    FIXED: "Miktarı",
  };

  const handleAmountOnChange = (e) => {
    let valid = true;
    if (e.target.value) {
      valid = checkOnChangeNumeric(e.target.value);
    }
    if (!valid) return false;

    const discounts = [...(formData?.discounts || [])];

    const index = discounts.findIndex((d) => d.type === e.target.id);
    if (index !== -1) {
      // Eğer bu kart zaten `discounts` içinde varsa, güncelle
      discounts[index].amount = e.target.value;
    } else {
      // Eğer yoksa, yeni bir obje olarak ekle
      discounts.push({
        type: e.target.id,
        discounttype: formData.discounttype || "PERCENT",
        amount: e.target.value,
      });
    }

    onChange("discounts", discounts);
    return valid;
  };

  const clearDiscounts = () => {
    if (!isEdit) {
      onChange("discounts", []);
      createDiscounts();
    }
  };

  useEffect(() => {
    if (formData?.province) {
      const found = ililce.find((element) => element._id == formData.province);
      setDistrict(found.district);
      console.log(found.district);
    }
  }, [formData.province]);

  console.log(formData, "tubu");

  return (
    <Form className="tk_mwp-form">
      <Row>
        <Col>
          <div>
            <p style={{ color: "black", fontSize: "20px" }}>Yetkili Kişi: </p>
          </div>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ad *</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Ad"
                  value={formData.name || ""}
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                  }}
                  isInvalid={formDataErrors.name}
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.name}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Soyad *</Form.Label>
                <Form.Control
                  type="text"
                  name="surname"
                  placeholder="Soyad"
                  value={formData.surname || ""}
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                  }}
                  isInvalid={formDataErrors.surname}
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.surname}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Kişisel Eposta </Form.Label>
            <Form.Control
              type="text"
              name="personalEmail"
              placeholder="Eposta"
              value={formData.personalEmail || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.personalEmail}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.personalEmail}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Yetkili Cep Telefonu *</Form.Label>
            <Form.Control
              as={InputMask}
              name="managerphone"
              placeholder="Cep Telefonu"
              value={formData.managerphone || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.managerphone}
              mask="0(999) 999 99 99"
              maskChar={null}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.managerphone}
            </Form.Text>
          </Form.Group>
          <div>
            <p style={{ color: "black", fontSize: "20px" }}>Üye İşyerinin: </p>
          </div>
          <Form.Group>
            <Form.Label>işletme adı *</Form.Label>
            <Form.Control
              type="text"
              name="entitle"
              placeholder="İşletme Adı"
              value={formData.entitle || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.entitle}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.entitle}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Vergi Kimlik Numarası * </Form.Label>
            <Form.Control
              as={InputMask}
              type="text"
              name="vkn"
              placeholder="Vergi Kimlik Numarası"
              value={formData.vkn || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.vkn}
              mask="99999999999"
              maskChar={null}
            />
            <Form.Text className="text-danger">{formDataErrors?.vkn}</Form.Text>
          </Form.Group>
          <div className="d-flex" style={{ gap: "5px" }}>
            <Form.Group style={{ width: formData.province ? "50%" : "100%" }}>
              <Form.Label>İl *</Form.Label>
              <TKSelect
                defaultTitle="İl Seç"
                selectedItem={parseInt(formData?.province)}
                itemSelector="province"
                items={ililce}
                itemOnClick={(id) => onChange("province", id)}
                isInvalid={formDataErrors.province}
                name="province"
              />
              <Form.Text className="text-danger">
                {formDataErrors?.province}
              </Form.Text>
            </Form.Group>
            {formData.province && district && (
              <Form.Group className="flex-fill">
                <Form.Label>İlçe *</Form.Label>
                <TKSelect
                  defaultTitle="İlçe Seç"
                  selectedItem={parseInt(formData?.district)}
                  itemSelector="district"
                  items={district}
                  itemOnClick={(id) => onChange("district", id)}
                  isInvalid={formDataErrors.district}
                  name="district"
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.district}
                </Form.Text>
              </Form.Group>
            )}
          </div>
          <Form.Group>
            <Form.Label>adres *</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="address"
              placeholder="Adres"
              value={formData.address || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.address}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.address}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Kontak Telefon *</Form.Label>
            <Form.Control
              as={InputMask}
              name="phone"
              placeholder="Kontak Telefon"
              value={formData.phone || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.phone}
              mask="0(999) 999 99 99"
              maskChar={null}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.phone}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Sabit Telefon</Form.Label>
            <Form.Control
              as={InputMask}
              name="landingPhone"
              placeholder="Sabit Telefon"
              value={formData.landingPhone || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              mask="9(999) 999 99 99"
              maskChar={null}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.landingPhone}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>internet sitesi</Form.Label>
            <Form.Control
              type="text"
              name="web_site"
              placeholder="ör: https://website.com/"
              value={formData.web_site || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              onBlur={(e) => {
                onChange(e.target.name, withHttp(formData.web_site));
              }}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.web_site}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>fotoğraf *</Form.Label>
            <TKFileInput
              selectedFile={formData.image}
              onChange={(file) => onChange("image", file)}
              className={formDataErrors?.image ? "tk_file-input-error" : ""}
              imgsrc={formData.previousimage}
              isEdit={isEdit}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.image}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>kategoriler *</Form.Label>
            <TKFilterDropdown
              variant="light"
              title="kategori seç"
              items={list}
              itemSelector="name"
              selectedItems={formData.category}
              itemOnClick={handleDropdownOnChange}
              isInvalid={formDataErrors.category}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.category}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Label>
            İndirim {amountLabel[formData.general_discount_type] ?? ""} *
          </Form.Label>
          <Form.Group className="mb-0">
            <Form.Check
              inline
              className="mb-2"
              type="radio"
              id="percent-discount"
              label="Yüzde İndirim"
              name="discounttype"
              value={PERCENT}
              defaultChecked={
                !isEdit || formData.discounts?.[0]?.discounttype === PERCENT
              }
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
                clearDiscounts();
              }}
            />
            <Form.Check
              inline
              className="mb-2"
              type="radio"
              id="fixed-discount"
              label="Sabit İndirim"
              name="discounttype"
              value={FIXED}
              defaultChecked={formData.discounts?.[0]?.discounttype === FIXED}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
                clearDiscounts();
              }}
            />
          </Form.Group>
          <Form.Group>
            {listCardTypes.map(({ name, _id }, index) => (
              <>
                <Form.Label>{`${name}`}</Form.Label>
                <Form.Control
                  type="text"
                  className="w-50"
                  id={_id}
                  name={name}
                  value={formData.discounts?.[index]?.amount || ""}
                  onChange={handleAmountOnChange}
                  isInvalid={formDataErrors?.discounts?.[index]?.amount}
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.discounts?.[index]?.amount}
                </Form.Text>
              </>
            ))}
          </Form.Group>

          {!isPublic && (
            <>
              <Form.Group>
                <Form.Label>Durum *</Form.Label>
                <TKSelect
                  defaultTitle="Üye İşyerinin Durumunu Seç"
                  selectedItem={formData?.status}
                  itemSelector="status"
                  items={status}
                  itemOnClick={(id) => onChange("status", id)}
                  isInvalid={formDataErrors.status}
                  name="status"
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.status}
                </Form.Text>
              </Form.Group>
            </>
          )}

          {!isPublic && formData?.status == "PASSIVE" && (
            <>
              <Form.Group>
                <Form.Label>Pasif nedeni *</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="rejection"
                  placeholder="Pasif nedeni"
                  value={formData.rejection || ""}
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                  }}
                  isInvalid={formDataErrors.rejection}
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.rejection}
                </Form.Text>
              </Form.Group>
            </>
          )}

          {!isPublic && formData?.status == "CANCELED" && (
            <>
              <Form.Group>
                <Form.Label>Red nedeni *</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="history_comment"
                  placeholder="Ret nedeni"
                  value={formData?.history_comment || ""}
                  readOnly={true}
                  /*onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                  }}
                  isInvalid={formDataErrors.rejection}
                  */
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.rejection}
                </Form.Text>
              </Form.Group>
            </>
          )}

          {!isPublic && (
            <>
              <Form.Group>
                <Form.Label>Online</Form.Label>
                <TKSelect
                  defaultTitle="Üye İşyerinin Online Durumunu Seç"
                  selectedItem={formData?.isOnline}
                  itemSelector="isOnline"
                  items={isOnline}
                  itemOnClick={(id) => onChange("isOnline", id)}
                  isInvalid={formDataErrors.isOnline}
                  name="isOnline"
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.isOnline}
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>sözleşme tarihi</Form.Label>
                <TKDatePicker
                  className={formDataErrors?.contract_date && "is-invalid"}
                  dateValue={formData.contract_date}
                  dateFormat="P"
                  placeholder={`ör: ${formatDate(new Date(), "P")}`}
                  onChange={(date) => onChange("contract_date", date)}
                  showTimeInput={false}
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.contract_date}
                </Form.Text>
              </Form.Group>
            </>
          )}
          <Form.Group
            className={formDataErrors?.detail ? "tk_ck-editor-error" : ""}
          >
            <Form.Label>firma detayları</Form.Label>
            <CKEditor
              config={{
                language: "tr",
                toolbar: {
                  items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "blockQuote",
                    "link",
                    "|",
                    "undo",
                    "redo",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                heading: {
                  options: [
                    {
                      model: "paragraph",
                      title: "Paragraph",
                      class: "ck-heading_paragraph",
                    },
                    {
                      model: "heading1",
                      view: "h3",
                      title: "Heading 1",
                      class: "ck-heading_heading1",
                    },
                    {
                      model: "heading2",
                      view: "h4",
                      title: "Heading 2",
                      class: "ck-heading_heading2",
                    },
                    {
                      model: "heading3",
                      view: "h5",
                      title: "Heading 3",
                      class: "ck-heading_heading3",
                    },
                  ],
                },
                link: {
                  defaultProtocol: "http://",
                },
              }}
              editor={ClassicEditor}
              data={formData.detail}
              onChange={(e, editor) => {
                const data = editor.getData();
                onChange("detail", data);
              }}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.detail}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Koordinatlar *</Form.Label>
            <Form.Text className="text-danger">
              {formDataErrors?.position}
            </Form.Text>
            <TKCoordinatesPicker
              position={formData.position}
              onChange={(pos) => onChange("position", pos)}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default TKMemberWorkplacesForm;

TKMemberWorkplacesForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {},
};
